export const productGroupNames = {
  1: 'Предметы одежды, белье постельное, столовое, туалетное и кухонное',
  2: 'Обувные товары',
  3: 'Табачная продукция',
  4: 'Духи и туалетная вода',
  5: 'Шины и покрышки пневматические резиновые новые',
  6: 'Фотокамеры (кроме кинокамер), фотовспышки и лампы-вспышки',
  7: 'Лекарственные средства',
  8: 'Молочная продукция',
  9: 'Велосипеды и велосипедные рамы',
  10: 'Кресла-коляски',
  11: 'Алкогольная продукция',
  12: 'Альтернативная табачная продукция',
  13: 'Питьевая вода',
  14: 'Товары из натурального меха',
  15: 'Пиво и пивные напитки',
  18: 'Бытовая техника',
  19: 'Спиртосодержащая непищевая продукция',
}

export const productGroupNamesForUrlParameters = {
  1: 'lp',
  2: 'shoes',
  3: 'tobacco',
  4: 'perfumery',
  5: 'tires',
  6: 'electronics',
  7: 'pharma',
  8: 'milk',
  9: 'bicycle',
  10: 'wheelchairs',
  11: 'alcohol',
  12: 'otp',
  13: 'water',
  14: 'furs',
  15: 'beer',
  19: 'antiseptic',
  18: 'appliances',
};
