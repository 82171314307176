import React from 'react';
import Grid from '@material-ui/core/Grid';
import { StepTitle } from '../Document/Document.styled';
import Input from '../../../../common_components/Form/MaterialFields/Input/Input';
import { required } from '../../../../common_components/Form/utils/validations';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import * as authSelectors from '../../../../common_components/Auth/Auth.selectors';
import Select from '../../../../common_components/Form/MaterialFields/Select/Select';
import { pgTypesNames } from '../../../../constants/productGroups';
import {
  DATA_INFO_TYPE_OPTIONS,
  getFieldForInfoType,
  SelectWrapper,
} from './constants';

const InfoFields = props => {
  const { formValues, user, finalConfirm, formMutators } = props;

  const { t } = useTranslation();

  const productGroupList = user
    ? user.product_group_info
        .filter(el => el.status === '5' && el.types.includes('IMPORTER'))
        .map(el => ({ value: el.name, label: t(pgTypesNames[el.name]) }))
    : [];

  const handleSelect = value => {
    const allInfoTypes = DATA_INFO_TYPE_OPTIONS.map(option => option.value);

    allInfoTypes.forEach(infoType => {
      if (formValues[infoType] && infoType !== value) {
        formMutators.updateField(infoType, undefined);
      }
    });
  };

  return (
    <React.Fragment>
      <Grid container>
        <Grid>
          <StepTitle noMarginTop>{t('Общие данные')}</StepTitle>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Input
            forceValidate
            name="inn"
            placeholder={t('ИНН/ПИНФЛ заявителя')}
            required
            validate={required}
            disabled
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <SelectWrapper isFinalConfirm={finalConfirm}>
            <Select
              forceValidate
              name="productGroup"
              disabled={finalConfirm}
              placeholder={t('Товарная группа')}
              required
              validate={required}
              options={productGroupList}
            />
          </SelectWrapper>
        </Grid>
      </Grid>

      <Grid container>
        <Grid>
          <StepTitle noMarginTop>{t('Изменяемые сведения')}</StepTitle>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <SelectWrapper isFinalConfirm={finalConfirm}>
            <Select
              forceValidate
              name="infoType"
              onSelect={value => handleSelect(value)}
              placeholder={t('Вид сведений')}
              options={DATA_INFO_TYPE_OPTIONS}
              disabled={finalConfirm}
              required
              validate={required}
            />
          </SelectWrapper>
        </Grid>
        <Grid item xs={3}>
          {getFieldForInfoType({ infoType: formValues.infoType, finalConfirm })}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const mapState = state => ({
  user: authSelectors.userInfo(state),
});

export default connect(mapState, null)(InfoFields);
