import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import 'datejs';
import moment from 'moment';
import 'moment/locale/ru';
import Tabs from '@crpt-ui/core/lib/Tabs/Tabs';
import { MuiThemeProvider } from '@material-ui/core';
import LightTheme from '../../common_components/_MaterialUi/LightTheme';

import * as actions from './ducks/CisDetails.actions';
import * as selectors from './ducks/CisDetails.selectors';

import Breadcrumbs from '../../common_components/Breadcrumbs/Breadcrumbs';
import { escapeUrlForRoutePath } from '../../utils';
import ContentWrapper from '../../common_components/styled/ContentWrapper';

import Preloader from '../../common_components/Preloader/Preloader';

import BasicInfo from './BasicInfo/BasicInfo';
import Agregation from './Agregation/Agregation';
import Documents from './Documents/Documents';
import Way from './Way/Way';
import { MaterialUiThemeKZ } from '../../common_components/_MaterialUi/ThemeKZ';
import { MaterialUiThemeUZ } from '../../common_components/_MaterialUi/ThemeUZ';
import { Translate } from '../../common_components/Translate/Translate';
import { get } from 'lodash';
import { language } from '../../selectors/LoginFormKep';

moment.locale('ru');

const propTypes = {};

class CisDetails extends Component {
  static propTypes = {
    match: PropTypes.shape({}).isRequired,
    history: PropTypes.shape({}).isRequired,
    data: PropTypes.shape({}).isRequired,
    loaded: PropTypes.bool.isRequired,
    countryCode: PropTypes.string.isRequired,
  };

  componentDidMount() {
    const { match, history, onMount } = this.props;
    const { id } = match.params;
    const formatted = decodeURIComponent(id);

    if (
      window.location.pathname === `/cis/list/${id}` ||
      window.location.pathname === `/cis/list/${id}/`
    ) {
      history.replace(`/cis/list/${id}/basic-info`);
    }

    onMount(formatted);
  }

  componentWillUnmount() {
    this.props.onUnmount();
  }

  componentDidUpdate(prevProps) {
    const { match, updateCis } = this.props;
    const id = match.params.id;
    const formatted = decodeURIComponent(id);
    const preparedFormatted = formatted.replace(/[()]/gi, '');

    const selectedCisID = decodeURIComponent(prevProps.match.params.id);
    const preparedSelectedCisID = selectedCisID.replace(/[()]/gi, '');

    if (preparedFormatted != preparedSelectedCisID) {
      updateCis(formatted);
    }
  }

  render() {
    const { match, history, data, loaded, countryCode, currentLanguage } = this.props;
    const { id } = match.params;
    const packageType = get(data, 'product.packageType', null);
    const showItem = {
      mrp:
        data && ['block', 'pack'].indexOf(Object.keys(data).length > 0 ? data.product.packageType : null) >= 0,
    };

    const formatted = decodeURIComponent(id);
    const breadcrumbs = [
      {
        href: '/cis/list',
        title: Translate('Коды'),
        id: 1,
      },
      {
        title: (
          <span style={{ fontWeight: 600 }}>
            {Translate('Код')}&nbsp;{formatted}
          </span>
        ),
        id: 2,
      },
    ];

    const tabs = [
      {
        id: 0,
        label: Translate('Общая информация'),
        route: `/cis/list/${id}/basic-info`,
        check: [`/cis/list/${id}/basic-info`],
      },
      {
        id: 1,
        label: Translate('Агрегация'),
        route: `/cis/list/${id}/agregation`,
        check: [`/cis/list/${id}/agregation`],
      },
      {
        id: 2,
        label: Translate('Документы'),
        route: `/cis/list/${id}/journal`,
        check: [`/cis/list/${id}/journal`],
      },
      {
        id: 3,
        label: Translate('Движение кода'),
        route: `/cis/list/${id}/way`,
        check: [`/cis/list/${id}/way`],
      },
    ].filter(
      item => (item.id === 1
          ? data && data.aggregatedTotal > 0
          : true
      ));

    const currentIndex = tabs.findIndex(
      v => v.check.indexOf(window.location.pathname) !== -1,
    );

    return (
      <MuiThemeProvider
        theme={countryCode === 'KZ' ? MaterialUiThemeKZ : MaterialUiThemeUZ}
      >
        <Breadcrumbs
          breadcrumbs={breadcrumbs}
          paddingTop="0"
          paddingBottom="17px"
        />

        <Tabs
          value={currentIndex}
          items={tabs.map(tab => ({
            ...tab,
            onClick: () => {
              history.push(tab.route);
            },
          }))}
          variant={'fullWidth'}
          textColor="inherit"
          style={{ marginBottom: '-20px' }}
        />

        <ContentWrapper>
          <Preloader text={Translate('Загрузка данных кода...')} />

          {loaded && (
            <Switch>
              <Route
                render={props => (
                  <BasicInfo currentLanguage={currentLanguage} countryCode={countryCode} data={data} showItem={showItem} {...props} />
                )}
                path={escapeUrlForRoutePath(`/cis/list/${id}/basic-info`)}
              />
              <Route
                render={props => (
                  <Agregation cisData={data} showItem={showItem} {...props} />
                )}
                path={escapeUrlForRoutePath(`/cis/list/${id}/agregation`)}
              />
              <Route
                render={props => <Documents data={data} {...props} />}
                path={escapeUrlForRoutePath(`/cis/list/${id}/journal`)}
              />
              <Route
                render={props => <Way data={data} {...props} />}
                path={escapeUrlForRoutePath(`/cis/list/${id}/way`)}
              />
            </Switch>
          )}
        </ContentWrapper>
      </MuiThemeProvider>
    );
  }
}

CisDetails.propTypes = propTypes;

const mapState = state => ({
  data: selectors.data(state),
  loaded: selectors.loaded(state),
  currentLanguage: language(state),
  countryCode: state.config.countryCode,
});

const mapDispatch = dispatch => ({
  onMount: id => dispatch(actions.mounted(id)),
  onUnmount: () => dispatch(actions.unmounted()),
  updateCis: id => dispatch(actions.updateCis(id)),
});

export default compose(connect(mapState, mapDispatch), withRouter)(CisDetails);
