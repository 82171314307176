import { countryCodeFlags } from 'src/constants/countryCodeFlags';

export const exportStatuses = {
  CHECKING: 'CHECKING',
  SENT: 'SENT',
  ERROR: 'ERROR',
  WAIT_ACCEPTANCE: 'WAIT_ACCEPTANCE',
  ACCEPTED: 'ACCEPTED',
}

export const importStatuses = {
  IN_PROGRESS: 'IN_PROGRESS',
  CHECKED_NOT_OK: 'CHECKED_NOT_OK',
  CHECKED_OK: 'CHECKED_OK',
  ACCEPTED: 'ACCEPTED',
  WAIT_ACCEPTANCE: 'WAIT_ACCEPTANCE',
}


export const documentTypes = {
  UNIVERSAL_TRANSFER_DOCUMENT: 'UNIVERSAL_TRANSFER_DOCUMENT',
  UNIVERSAL_TRANSFER_DOCUMENT_FIX: 'UNIVERSAL_TRANSFER_DOCUMENT_FIX',
  UNIVERSAL_CORRECTION_DOCUMENT: 'UNIVERSAL_CORRECTION_DOCUMENT',
  UNIVERSAL_CORRECTION_DOCUMENT_FIX: 'UNIVERSAL_CORRECTION_DOCUMENT_FIX',
  UNIVERSAL_TRANSFER_DOCUMENT_UNSIGNED: 'UNIVERSAL_TRANSFER_DOCUMENT_UNSIGNED',
  UNIVERSAL_CORRECTION_DOCUMENT_UNSIGNED:
    'UNIVERSAL_CORRECTION_DOCUMENT_UNSIGNED',
  AGGREGATION_DOCUMENT: 'AGGREGATION_DOCUMENT',
  WRITE_OFF: 'WRITE_OFF',
  LP_FTS_INTRODUCE_RESPONSE: 'LP_FTS_INTRODUCE_RESPONSE',
  MTTPS_10: 'MTTPS_10',
  MTTPS_90: 'MTTPS_90',
  IMPORT: 'IMPORT',
  IMPORT_TRANSIT: 'IMPORT_TRANSIT',
  PAYMENT: 'PAYMENT',
  RECEIPT: 'RECEIPT',
  RECEIPT_RETURN: 'RECEIPT_RETURN',
  KZ_INTRODUCE_GOODS: 'KZ_INTRODUCE_GOODS',
  KZ_UPD: 'KZ_UPD',
  KZ_EXPORT_NOTICE: 'KZ_EXPORT_NOTICE',
  1200: 1200,
  1300: 1300,
  UZ_ESF: 'UZ_ESF',
  AIC_AGGREGATION: 'AIC_AGGREGATION',
  AIC_DISAGGREGATION: 'AIC_DISAGGREGATION',
  INVENTORY: 'INVENTORY',
  TRADE_INVENTORY: 'TRADE_INVENTORY',
  UZ_ESF_COR: 'UZ_ESF_COR',
  UZ_ESF_ADD: 'UZ_ESF_ADD',
  EAS_CROSSBORDER: 'EAS_CROSSBORDER',
  LP_ACCEPT_GOODS: 'LP_ACCEPT_GOODS',
  UZ_ADMIT_APPLICATION: 'UZ_ADMIT_APPLICATION',
  CUSTOMS_DECLARATION: 'CUSTOMS_DECLARATION',
  COR_INFO_CM: 'COR_INFO_CM',
};

export const KZdocumentTypes = {
  KZ_UPD: 'KZ_UPD',
  IMPORT_TRANSIT: 'IMPORT_TRANSIT',
  IMPORT: 'IMPORT',
  KZ_INTRODUCE_GOODS: 'KZ_INTRODUCE_GOODS',
  MTTPS_90: 'MTTPS_90',
  AGGREGATION_DOCUMENT: 'AGGREGATION_DOCUMENT',
  WRITE_OFF: 'WRITE_OFF',
  EAS_CROSSBORDER: 'EAS_CROSSBORDER',
  LP_ACCEPT_GOODS: 'LP_ACCEPT_GOODS',
};

export const KZdocumentTypesNumbers = {
  1000: 'Акт приема-передачи  товаров',
  1200: 'Уведомление о ввозе товаров (ЕАЭС)',
};

export const UZdocumentTypes = {
  IMPORT: 'IMPORT',
  CUSTOMS_DECLARATION: 'CUSTOMS_DECLARATION',
  AIC_DISAGGREGATION: 'AIC_DISAGGREGATION',
  AIC_AGGREGATION: 'AIC_AGGREGATION',
  INVENTORY: 'INVENTORY',
  TRADE_INVENTORY: 'TRADE_INVENTORY',
  AGGREGATION_DOCUMENT: 'AGGREGATION_DOCUMENT',
  WRITE_OFF: 'WRITE_OFF',
  MTTPS_90: 'MTTPS_90',
  UZ_ESF: 'UZ_ESF',
  UZ_ESF_COR: 'UZ_ESF_COR',
  UZ_ESF_ADD: 'UZ_ESF_ADD',
  COR_INFO_CM: 'COR_INFO_CM',
};

export const receiptTypes = {
  RECEIPT: 'RECEIPT',
  RECEIPT_RETURN: 'RECEIPT_RETURN',

  // CisDetails receipts types
  RETURN_TO_TURNOVER: 'RETURN_TO_TURNOVER',
  WITHDRAW: 'WITHDRAW',
  AUTO_DISAGGREGATION: 'AUTO_DISAGGREGATION',
};

export const documentTypeNames = {
  UNIVERSAL_TRANSFER_DOCUMENT: 'УПД',
  UNIVERSAL_TRANSFER_DOCUMENT_FIX: 'УПДИ',
  UNIVERSAL_CORRECTION_DOCUMENT: 'УКД',
  UNIVERSAL_CORRECTION_DOCUMENT_FIX: 'УКДИ',
  UNIVERSAL_TRANSFER_DOCUMENT_UNSIGNED: 'ПД',
  UNIVERSAL_CORRECTION_DOCUMENT_UNSIGNED: 'КД',
  AGGREGATION_DOCUMENT: 'Агрегирование',
  RECEIPT: 'Чек',
  KZ_EXPORT_NOTICE: 'Уведомление об экспорте в ЕАЭС',
  RECEIPT_RETURN: 'Чек возврата',
  WRITE_OFF: 'Документ списания',
  LP_FTS_INTRODUCE_RESPONSE: 'Декларация на товары',
  MTTPS_10:
    'Заявление о регистрации в качестве участника Эксперимента (МТПФ-10)',
  MTTPS_90: 'Уведомление о выводе из оборота',
  KZ_INTRODUCE_GOODS: 'Уведомление о вводе в оборот',
  IMPORT: countryCodeFlags.isUZ
    ? 'Уведомление о ввозе (импорт)'
    : 'Уведомление о ввозе товаров (третьи страны)',
  IMPORT_TRANSIT: 'Уведомление о ввозе товаров (ЕАЭС)',
  KZ_UPD: 'Акт приема-передачи товаров',
  PAYMENT: 'Оплата',
  '1000': 'Акт приема-передачи  товаров',
  '1100': 'Акт приема-передачи  товаров (корректировочный)',
  '1005': 'Уведомление о расхождениях',
  '1200': 'Уведомление о ввозе товаров (ЕАЭС)',
  '1300': 'Уведомление о ввозе товаров ЕАЭС (корректировочный)',
  AIC_AGGREGATION: 'Формирование АИК',
  AIC_DISAGGREGATION: 'Расформирование АИК',
  CUSTOMS_DECLARATION: 'Сведения о ГТД',

  // CisDetails documents types
  INTRODUCED: 'Уведомление о ввозе товаров',
  EMISSION: 'Агрегирование',
  APPLICATION: 'Агрегирование',
  AGGREGATION: 'Агрегирование',
  AUTO_DISAGGREGATION: 'Дезагрегирован',
  DOCUMENT_FIX: 'УКДи',
  DOCUMENT_CORRECTION: 'УКД',
  OWNER_CHANGE: 'Акт приема-передачи товаров',
  RETURN_TO_TURNOVER: 'Чек возврата',
  UNDEFINED: 'Не определен',
  WITHDRAW: 'Чек',
  INVENTORY: 'Уведомление о товарных остатках (производство)',
  TRADE_INVENTORY: 'Уведомление о товарных остатках (опт, розница)',
  UZ_ESF: 'Электронный счет-фактура',
  UZ_ESF_COR: 'Электронный счет-фактура (исправленный)',
  UZ_ESF_ADD: 'Электронный счет-фактура (дополнительный)',
  EAS_CROSSBORDER: 'Уведомление об отгрузке ЕАЭС',
  LP_ACCEPT_GOODS: 'Уведомление о приемке товаров ЕАЭС',
  UZ_ADMIT_APPLICATION: 'Заявка на регистрацию кодов ИС МДЛП',
  COR_INFO_CM: 'Корректировка сведений о кодах маркировки',
};

export const documentTypeNumbers = {
  UNIVERSAL_TRANSFER_DOCUMENT: '1',
  UNIVERSAL_TRANSFER_DOCUMENT_FIX: '10',
  UNIVERSAL_CORRECTION_DOCUMENT: '7',
  UNIVERSAL_CORRECTION_DOCUMENT_FIX: '11',
  UNIVERSAL_TRANSFER_DOCUMENT_UNSIGNED: '12',
  UNIVERSAL_CORRECTION_DOCUMENT_UNSIGNED: '13',
  AGGREGATION_DOCUMENT: '2',
  RECEIPT: '6',
  RECEIPT_RETURN: '8',
  WRITE_OFF: '9',
  MTTPS_10: '16',
  MTTPS_90: '23',
  IMPORT: '34',
  IMPORT_TRANSIT: '99',
  PAYMENT: 'Оплата',
};

export const documentTypeNamesNumbers = {
  ...Object.keys(documentTypeNumbers).reduce((obj, current) => {
    obj[documentTypeNumbers[current]] = documentTypeNames[current];
    return obj;
  }, {}),
};
