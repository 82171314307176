import React from 'react';
import { historyOperationTypes } from 'src/constants';

import Aggregation from './Items/Aggregation';
import Application from './Items/Application';
import AutoDisaggregation from './Items/AutoDisaggregation';
import Disaggregation from './Items/Disaggregation';
import DocumentCorrection from './Items/DocumentCorrection';
import DocumentFix from './Items/DocumentFix';
import Emission from './Items/Emission';
import Export from './Items/Export';
import OwnerChange from './Items/OwnerChange';
import Payment from './Items/Payment';
import Withdraw from './Items/Withdraw';
import WriteOff from './Items/WriteOff';
import ReturnToTurnover from './Items/ReturnToTurnover';
import Introduced from './Items/Introduced';
import CorInfoCm from './Items/CorInfoCm';

export const getOp = op => {
  switch (op.type) {
    case historyOperationTypes.EMISSION:
      return <Emission op={op} />; // ссылка не нужна
    case historyOperationTypes.PAYMENT:
      return <Payment op={op} />; // ссылка не нужна
    case historyOperationTypes.APPLICATION:
      return <Application op={op} />; // ссылка не нужна
    case historyOperationTypes.AGGREGATION:
      return <Aggregation op={op} />;
    case historyOperationTypes.OWNER_CHANGE:
      return <OwnerChange op={op} />;
    case historyOperationTypes.DISAGGREGATION:
      return <Disaggregation op={op} />;
    case historyOperationTypes.WITHDRAW:
      return <Withdraw op={op} />;
    case historyOperationTypes.AUTO_DISAGGREGATION:
      return <AutoDisaggregation op={op} />;
    case historyOperationTypes.EXPORT:
      return <Export op={op} />;
    case historyOperationTypes.WRITE_OFF:
      return <WriteOff op={op} />;
    case historyOperationTypes.DOCUMENT_FIX:
      return <DocumentFix op={op} />;
    case historyOperationTypes.DOCUMENT_CORRECTION:
      return <DocumentCorrection op={op} />;
    case historyOperationTypes.RETURN_TO_TURNOVER:
      return <ReturnToTurnover op={op} />;
    case historyOperationTypes.INTRODUCED:
      return <Introduced op={op} />;
    case historyOperationTypes.CORRECTION_CIS:
      return <CorInfoCm op={op} />;
    default:
      return null;
  }
};
