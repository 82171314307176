import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core';
import { escapeUrlForRoutePath } from 'src/utils';
import * as AppSelectors from 'src/common_components/App/App.selectors';
import * as actions from './ducks/DocumentDetails.actions';
import * as selectors from './ducks/DocumentDetails.selectors';
import { esfTabs, tabs } from './DocumentDetails.constants';
import {
  checkNeedTabs,
  getCodesRoute, getErrorsRoute,
  getGoodsRoute,
  getTotalRoute,
  isDownloadButtonHided,
  prepareTabs,
} from './DocumentDetails.utils';
import DocumentDetailsView from './DocumentDetails.view';
import { BasicInfoFabric } from './BasicInfoFabric';
import * as preloaderSelectors from 'src/common_components/Preloader/ducks/Preloader.selectors';
import { documentStatusesNames, importStatusesNames } from 'src/constants/documentStatuses';
import { documentTypeNames, documentTypes } from 'src/constants/documentTypes';
import { MaterialUiThemeKZ } from 'src/common_components/_MaterialUi/ThemeKZ';
import { MaterialUiThemeUZ } from '../../common_components/_MaterialUi/ThemeUZ';
import { Translate } from '../../common_components/Translate/Translate';
import { useLoadAndUpdate } from './useLoadAndUpdate';
import ActDocument from '../../motp_components/_Requests/RequestList/ActDocument/ActDocument';
import EaesImportDocument from '../../motp_components/_Requests/RequestList/EaesImportDocument/EaesImportDocument';

const DocumentDetails = props => {
  const { match, loaded, countryCode, selectedDocument } = props;
  const { id } = match.params;
  const decodedId = decodeURIComponent(id);
  const isAdmits = match.path.split('/')[2] === 'admits';

  useLoadAndUpdate({ ...props, id, decodedId, isAdmits });

  const onTabClick = useCallback(to => {
    const id = props.match.params.id;
    props.history.push(`/documents/${isAdmits ? 'admits' : 'list'}/${id}/${to}`);
  }, [id]);

  const onNavClick = useCallback(loc => {
    props.history.push(loc);
  }, [id]);

  const onDownloadJSONClick = useCallback(() => {
    const { onDownloadJSON } = props;
    onDownloadJSON(decodedId);
  }, []);

  const tabList = useMemo(() => [
    documentTypes.UZ_ESF,
    documentTypes.UZ_ESF_COR,
    documentTypes.UZ_ESF_ADD].includes(selectedDocument.type)
      ? esfTabs
      : tabs,
    [selectedDocument]);

  const currentTab = useCallback(
    () =>
      tabList.find(
        tab =>
          tab.to &&
          location.pathname.indexOf(`/documents/${isAdmits ? 'admits' : 'list'}/${id}/${tab.to}`) === 0,
      ),
    [location.pathname, tabList],
  )();

  const documentTypeName = documentTypeNames[selectedDocument.type] || '—';

  const documentStatusName =
    documentStatusesNames[selectedDocument.status] || '';

  const breadCrumbTitle = () => {
    switch (selectedDocument.type) {
      case documentTypes.LP_FTS_INTRODUCE_RESPONSE:
        return (
          <React.Fragment>
            {documentTypeName ? Translate(documentTypeName) : ''} {decodedId}
          </React.Fragment>
        );
      case documentTypes.AIC_AGGREGATION:
      case documentTypes.CUSTOMS_DECLARATION:
      case documentTypes.AIC_DISAGGREGATION:
      case documentTypes.INVENTORY:
      case documentTypes.TRADE_INVENTORY:
      case documentTypes.COR_INFO_CM:
        return (
          <React.Fragment>
            {documentTypeName ? Translate(documentTypeName) : ''}
          </React.Fragment>
        );
      case documentTypes.MTTPS_90:
      case documentTypes.KZ_INTRODUCE_GOODS:
        return <React.Fragment>{Translate(documentTypeName)}</React.Fragment>;
      case documentTypes.KZ_UPD:
        return (
          <React.Fragment>
            {documentStatusName ? Translate(documentStatusName) : ''}&nbsp;
            {documentTypeName ? Translate(documentTypeName) : ''}&nbsp;
            {decodedId.replace('EEU_ACT_', '')}
          </React.Fragment>
        );
      case documentTypes[1200]:
      case documentTypes[1300]:
        return (
          <React.Fragment>
            {documentStatusName ? Translate(documentStatusName) : ''}&nbsp;
            {documentTypeName ? Translate(documentTypeName) : ''}&nbsp;
            {selectedDocument.number}
          </React.Fragment>
        );
      case documentTypes.EAS_CROSSBORDER:
      case documentTypes.LP_ACCEPT_GOODS: {
        const status = importStatusesNames[selectedDocument.status];
        return (
          <React.Fragment>
            {status ? Translate(status) : ''}&nbsp;
            {documentTypeName ? Translate(documentTypeName) : ''}&nbsp;
            {decodedId}
          </React.Fragment>
        );
      }
      default:
        return (
          <React.Fragment>
            {documentStatusName ? Translate(documentStatusName) : ''}&nbsp;
            {documentTypeName ? Translate(documentTypeName) : ''}&nbsp;
            {decodedId}
          </React.Fragment>
        );
    }
  };

  const breadcrumbs = [
    {
      href: '/documents/list',
      title: Translate('Документы'),
      id: 1,
    },
    {
      href: isAdmits ? `/documents/admits/${id}` : `/documents/list/${id}`,
      title: <span style={{ fontWeight: 600 }}>{breadCrumbTitle()}</span>,
      id: 2,
    },
  ];

  const theme = useMemo(() =>
    countryCode === 'KZ' ? MaterialUiThemeKZ : MaterialUiThemeUZ[countryCode],
  );

  const hasErrorTab = useMemo(() => selectedDocument.documentErrors
    && selectedDocument.documentErrors.length > 0, [selectedDocument]);

  let alternative;
  let switcher = false;
  if (id.includes('act')) {
    switcher = true;
    alternative = <ActDocument direction={id} match={match} mode="editedAct" />
  } else if (id.includes('eaes')) {
    switcher = true;
    alternative = (
      <EaesImportDocument
          direction={id}
          breadcrumbs={breadcrumbs}
          onNavClick={onNavClick}
          match={match}
      />
    );
  }

  const changeTabName = (tab) => {
    if (tab.to !== 'errors') return tab
    switch (selectedDocument.type) {
      case documentTypes.UZ_ESF:
      case documentTypes.UZ_ESF_ADD:
      case documentTypes.UZ_ESF_COR:
      case documentTypes.AIC_AGGREGATION:
      case documentTypes.AIC_DISAGGREGATION:
      case documentTypes.CUSTOMS_DECLARATION:
      case documentTypes.IMPORT:
      case documentTypes.WRITE_OFF:
      case documentTypes.AGGREGATION_DOCUMENT:
        return ({...tab, label: Translate('Ошибки обработки')})
      default:
        return ({...tab, label: Translate('Ошибки')})
    }
  }

  const setTabClick = (tab) => ({
    ...tab,
    onClick: () => {
      onTabClick(tab.to);
    },
  })

  return (
    <MuiThemeProvider theme={theme}>
      {switcher ? (
        alternative
      ) : (
        <DocumentDetailsView
          currentTabIndex={
            currentTab
              ? prepareTabs(selectedDocument, hasErrorTab).indexOf(currentTab)
              : 0
          }
          tabs={prepareTabs(selectedDocument, hasErrorTab).map(changeTabName).map(setTabClick)}
          needTabs={checkNeedTabs(selectedDocument)}
          loaded={loaded}
          breadcrumbs={breadcrumbs}
          onNavClick={onNavClick}
          onDownloadJSONClick={onDownloadJSONClick}
          hideDownloadButton={isDownloadButtonHided(selectedDocument.type,countryCode)}
        >
          <Switch>
            <Route
              render={props => getTotalRoute(selectedDocument, { countryCode })}
              path={escapeUrlForRoutePath(`/documents/list/${id}/total`)}
            />
            <Route
              render={props => getErrorsRoute(selectedDocument)}
              path={escapeUrlForRoutePath(`/documents/list/${id}/errors`)}
            />
            <Route
              render={props => getGoodsRoute(selectedDocument, { countryCode })}
              path={escapeUrlForRoutePath(`/documents/list/${id}/goods`)}
            />
            <Route
              render={props => getGoodsRoute(selectedDocument, { countryCode })}
              path={escapeUrlForRoutePath(`/documents/admits/${id}/goods`)}
            />
            <Route
              render={props =>
                getCodesRoute(selectedDocument, decodedId, { countryCode })
              }
              path={escapeUrlForRoutePath(`/documents/list/${id}/codes`)}
            />
            <Route
              render={() => (
                <React.Fragment>
                  <BasicInfoFabric
                    selectedDocument={selectedDocument}
                    id={decodedId}
                    countryCode={countryCode}
                  />
                </React.Fragment>
              )}
              path={escapeUrlForRoutePath(`/documents/list/${id}`)}
            />
            <Route
              render={() => (
                <React.Fragment>
                  <BasicInfoFabric
                    selectedDocument={selectedDocument}
                    id={decodedId}
                    countryCode={countryCode}
                  />
                </React.Fragment>
              )}
              path={escapeUrlForRoutePath(`/documents/admits/${id}`)}
            />
          </Switch>
        </DocumentDetailsView>
      )}
    </MuiThemeProvider>
  );
};

DocumentDetails.propTypes = {
  selectedDocument: PropTypes.shape({}).isRequired,
  onUnmount: PropTypes.func.isRequired,
};

const mapState = state => ({
  loaded: selectors.loaded(state),
  selectedDocument: selectors.selectedDocument(state),
  isLoading: preloaderSelectors.loading(state),
  countryCode: AppSelectors.countryCode(state),
});

const mapDispatch = dispatch => ({
  onMount: id => dispatch(actions.mounted(id)),
  onUnmount: () => dispatch(actions.unmounted()),
  onDownloadJSON: id => dispatch(actions.downloadJSON(id)),
  disaggregation: list => dispatch(actions.disaggregation(list)),
});

export default connect(mapState, mapDispatch)(DocumentDetails);
