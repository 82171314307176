import React from 'react';

import { documentTypes } from 'src/constants/documentTypes';

import UTDBasicInfo from './UTDBasicInfo';
import UTDfBasicInfo from './UTDfBasicInfo';
import AggregationBasicInfo from './AggregationBasicInfo';
import InOutputBasicInfo from './InOutputBasicInfo';
import TransgranBasicInfo from './TransgranBasicInfo';
import ImportKZBasicInfo from './ImportBasicInfo/ImportKzBaseInfo';
import ImportUzBasicInfo from './ImportBasicInfo/ImportUzBasicInfo';
import EasBasicInfo from './EasBasicInfo/EasBasicInfo';
import {Declaration} from '../Declaration/Info'
import WriteOffBasicInfo from './WriteOffBasicInfo';
import ActBasicInfo from './ActBasicInfo';
import EsfBasicInfo from './EsfBasicInfo/EsfBasicInfo';
import { EasCrossborderBasicInfo } from '../EasCrossborder/EasCrossborderBasicInfo';
import { AcceptGoodsBasicInfo } from '../AcceptGoods/AcceptGoodsBasicInfo';
import { CisRegistrationBasicInfo } from '../CisRegistration/CisRegistrationBasicInfo';
import AicBasicInfo from './AicInfo';
import AicDisaggregationInfo from './AicDisaggregationInfo';
import CustomsDeclarationInfo from './CustomsDeclarationInfo';
import { countryCodeFlags } from '../../../constants';
import WithdrawalFromCirculation from '../WithdrawalFromCirculation/WithdrawalFromCirculation';
import InventoryInfo from "./InventoryInfo";
import CorInfoCmInfo from './CorInfoCmInfo';

export function BasicInfoFabric(props) {
  const selectedDocument = props.selectedDocument;
  if (!selectedDocument) return null;

  switch (selectedDocument.type) {
    case documentTypes.LP_FTS_INTRODUCE_RESPONSE:
      return <Declaration {...props} />;
    // case documentTypes.WRITE_OFF:
    case documentTypes.MTTPS_90:
      if (countryCodeFlags.isUZ) {
        return <WithdrawalFromCirculation {...props} />
      } else return <InOutputBasicInfo {...props} />;
    case documentTypes.KZ_INTRODUCE_GOODS:
      return <InOutputBasicInfo {...props} />;
    case documentTypes.AGGREGATION_DOCUMENT:
      return <AggregationBasicInfo {...props} />;
    case documentTypes.UNIVERSAL_TRANSFER_DOCUMENT_FIX:
    case documentTypes.UNIVERSAL_CORRECTION_DOCUMENT_FIX:
      return <UTDfBasicInfo {...props} />;
    case documentTypes.TRANSGRAN_IMPORT:
    case documentTypes.TRANSGRAN_EXPORT:
      return <TransgranBasicInfo {...props} />;
    case documentTypes.IMPORT:
      return props.countryCode === 'UZ' ?
        <ImportUzBasicInfo {...props} /> :
        <ImportKZBasicInfo {...props} />;
    case documentTypes.IMPORT_TRANSIT:
        return <EasBasicInfo {...props} />;
    case documentTypes.WRITE_OFF:
      return <WriteOffBasicInfo {...props} />;
    case documentTypes.KZ_UPD:
      return <ActBasicInfo {...props} />;
    case documentTypes.EAS_CROSSBORDER:
      return <EasCrossborderBasicInfo {...props} />;
    case documentTypes.UZ_ADMIT_APPLICATION:
      return <CisRegistrationBasicInfo {...props} />;
    case documentTypes.LP_ACCEPT_GOODS:
      return <AcceptGoodsBasicInfo {...props} />;
    case documentTypes.UZ_ESF:
    case documentTypes.UZ_ESF_COR:
    case documentTypes.UZ_ESF_ADD:
      return <EsfBasicInfo {...props} />;
    case documentTypes.AIC_AGGREGATION:
      return <AicBasicInfo {...props} />;
    case documentTypes.INVENTORY:
    case documentTypes.TRADE_INVENTORY:
      return <InventoryInfo {...props} />;
    case documentTypes.AIC_DISAGGREGATION:
      return <AicDisaggregationInfo {...props} />;
    case documentTypes.COR_INFO_CM:
      return <CorInfoCmInfo {...props} />;
    case documentTypes.CUSTOMS_DECLARATION:
      return <CustomsDeclarationInfo {...props} />;
    case documentTypes.UNIVERSAL_TRANSFER_DOCUMENT:
    default:
      return <UTDBasicInfo {...props} />;
  }
}
