export const ProductGroupEnum = Object.freeze({
  ALCOHOL: 11,
  BEER: 15,
  BICYCLE: 9,
  ELECTRONICS: 6,
  FURS: 14,
  LP: 1,
  MILK: 8,
  OTP: 12,
  PERFUMERY: 4,
  SHOES: 2,
  TIRES: 5,
  TOBACCO: 3,
  WATER: 13,
  WHEELCHAIRS: 10,
  PHARMA: 7,
  APPLIANCES: 18,
  ANTISEPTIC: 19,
});

export default ProductGroupEnum;
