import { ProductGroupEnum } from './ProductGroupEnum';
import { Translate } from '../../Translate/Translate';

export const ProductGroupNamesMap = Object.freeze({
  [ProductGroupEnum.ALCOHOL]: Translate('Алкогольная продукция'),
  [ProductGroupEnum.BICYCLE]: Translate('Велосипеды и велосипедные рамы'),
  [ProductGroupEnum.ELECTRONICS]: Translate(
    'Фотокамеры (кроме кинокамер), фотовспышки и лампы-вспышки',
  ),
  [ProductGroupEnum.FURS]: Translate('Товары из натурального меха'),
  [ProductGroupEnum.LP]: Translate(
    'Предметы одежды, белье постельное, столовое, туалетное и кухонное',
  ),
  [ProductGroupEnum.MILK]: Translate('Молочная продукция'),
  [ProductGroupEnum.OTP]: Translate('Альтернативная табачная продукция'),
  [ProductGroupEnum.PERFUMERY]: Translate('Духи и туалетная вода'),
  [ProductGroupEnum.SHOES]: Translate('Обувные товары'),
  [ProductGroupEnum.TIRES]: Translate(
    'Шины и покрышки пневматические резиновые новые',
  ),
  [ProductGroupEnum.TOBACCO]: Translate('Табачные изделия'),
  [ProductGroupEnum.WATER]: Translate('Питьевая вода'),
  [ProductGroupEnum.WHEELCHAIRS]: Translate('Кресла-коляски'),
  [ProductGroupEnum.PHARMA]: Translate('Лекарственные средства'),
  [ProductGroupEnum.APPLIANCES]: Translate('Бытовая техника'),
  [ProductGroupEnum.BEER]: Translate('Пиво и пивные напитки'),
  [ProductGroupEnum.ANTISEPTIC]: Translate('Спиртосодержащая непищевая продукция'),
});

export default ProductGroupNamesMap;
