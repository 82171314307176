import { Translate } from '../../../common_components/Translate/Translate';
import styled from 'styled-components';
import moment from 'moment';
import React from 'react';
import { get } from 'lodash';
import Tooltip from '../../../common_components/_MaterialUi/Tooltip/Tooltip';
import { Link } from 'react-router-dom';

export const limit = 10;

export const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto 36px;
  height: 70px;
`;

const statuses = {
  CREATED: 'CREATED',
  SIGNED: 'SIGNED',
  VALIDATED: 'VALIDATED',
  CHECKED: 'CHECKED',
  PROCESSING: 'PROCESSING',
  FAILED: 'FAILED',
  SUCCESS: 'SUCCESS'
}

export const statusNames = {
  [statuses.CREATED]: 'Создан',
  [statuses.SIGNED]: 'Подписан',
  [statuses.VALIDATED]: 'Провалидирован НИС МПТ',
  [statuses.CHECKED]: 'Проверен в ИС МДЛП',
  [statuses.PROCESSING]: 'Обрабатывается',
  [statuses.FAILED]: 'Обработан с ошибкой',
  [statuses.SUCCESS]: 'Обработан успешно',
}

export const columns = [
  {
    Header: Translate('Регистрационный номер'),
    id: 'documentId',
    accessor: 'documentId',
    width: 'auto',
  },
  {
    Header: Translate('Дата загрузки документа'),
    id: 'documentDate',
    // accessor: 'documentDate',
    accessor: (data)=> moment(data.documentDate).format('DD.MM.YYYY'),
    width: 200,
  },
  {
    Header: Translate('Отправитель'),
    id: 'senderName',
    accessor: (data)=> `${data.senderInn} ${data.senderName}`,
    width: 'auto',
  },
  {
    Header: Translate('Статус обработки'),
    id: 'status',
    accessor: data => Translate(statusNames[data.status]),
    width: 'auto',
  },
];

const DraftNumWrap = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const Wrapper = styled.div`
  cursor: pointer;
  display: flex;
  width: auto !important;
  align-items: center;
  justify-content: flex-start;
  padding: 0 16px;
  color: #55b5e7;
  font-weight: 600;
`;

const StyledLink = styled(Link)`
  color: inherit;
  font-weight: inherit;
  text-decoration: none;
  z-index: 1001;
`;

export const options = [
  {
    accessor: d => d,
    id: 'documentId',
    render: (_, rowInfo) => {
      const uuid = get(rowInfo, 'original.documentId', '');
      const path = `/documents/admits/${uuid}`;
      return (
        <div
        >
          <DraftNumWrap>
            <Tooltip
              text={uuid}
              textComponent={
                <Wrapper>
                  <StyledLink to={path}>{uuid.slice(-6)}</StyledLink>
                </Wrapper>
              }
              sliceTitleStart={-6}
              placement="bottom-start"
              textColor="#55b5e7"
              interactive
              showCopy
              showMail
              noWrap
            />
          </DraftNumWrap>
        </div>
      );
    },
  },
];
