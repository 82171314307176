import React from 'react';
import { DocWrap, IdValue } from './CisRegistration.styles';
import { Grid } from '@material-ui/core';
import {
  DividerOfContent,
  DocumentTitleName
} from '../../../motp_components/_Requests/RequestList/Document/Document.styled';
import { Translate } from '../../../common_components/Translate/Translate';
import { Button as SButton, themes as BUTTON_THEMES } from '@crpt/react-button';
import Icon from '@crpt/react-icon';
import { getDocumentStatusIconByDocStatus } from '../DocumentDetails.utils';
import { importStatusesNames } from '../../../constants/documentStatuses';
import { Accord, Content } from '../Act/Showing.styled';
import ProductGroupNamesMap from '../../../common_components/Balance/enums/ProductGroupNamesMap';
import { ProductGroupEnum } from '../../../common_components/Balance/enums';
import moment from 'moment';
import { countryCodes } from '../../../constants';

export const CisRegistrationBasicInfo = ({ selectedDocument = {}, id }) => {
  const date = moment(selectedDocument.documentDate).format('DD.MM.YYYY');
  const inn = `${selectedDocument.senderInn || "-"} / ${selectedDocument.senderName || "-"}`;
  const emissionCountry = selectedDocument.emissionCountry ? selectedDocument.emissionCountry.toUpperCase() : ''
  const foundCountry = countryCodes.find(item=> item.type === emissionCountry) || {};
  const country = foundCountry.title || '-';

  return (
    <DocWrap>
      <React.Fragment>
        <Grid container justify="space-between">
          <Grid item>
            <DocumentTitleName>
              {Translate('Заявка на регистрацию кодов ИС МДЛП от ')}{date}
              <IdValue>{id}</IdValue>{' '}
            </DocumentTitleName>
          </Grid>
          <Grid item>
            <SButton theme={BUTTON_THEMES.whiteTheme}>
              <Icon
                type={getDocumentStatusIconByDocStatus(selectedDocument.status)}
                spacingRight={9}
              />
              {Translate(importStatusesNames[selectedDocument.status])}
            </SButton>
          </Grid>
        </Grid>
        <br/>
        <DividerOfContent/>
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Accord onClick={() => {}}>
              <span>{Translate('Общие данные')}</span>
            </Accord>
          </Grid>
          <Grid item xs={12}>
            {Translate('ИНН (или аналог)/Наименование заявителя')} <br/>
            <Content>{inn}</Content>
          </Grid>
          <Grid item xs={12}>
            {Translate('Товарная группа')} <br/>
            <Content>{ProductGroupNamesMap[ProductGroupEnum.PHARMA]}</Content>
          </Grid>
          <Grid item xs={12}>
            {Translate('Страна эмиссии')} <br/>
            <Content>{country}</Content>
          </Grid>
        </Grid>
      </React.Fragment>
    </DocWrap>
  );
}
