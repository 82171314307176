import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/ru';
import { intersection } from 'lodash';
import { ContentWrapper } from '../../common/styled';
import { getOrgRoles } from '../../../utils/userUtils';
import { historyOperationTypes, PARTICIPANT_TYPES } from '../../../constants';
import Operation from './Operation/Operation';
import { DayWrap, DateWrap } from './Way.styled';
import { composeByDay } from './Way.utils';
import { orderBy } from 'lodash';

moment.locale('ru');

class Way extends Component {
  constructor(props) {
    super(props);

    this.renderOp = this.renderOp.bind(this);
    this.state = {
      history: null,
    };
  }

  renderOp(op, i) {
    const orgRoles = getOrgRoles();
    const canSeePayment = !!intersection(orgRoles, [
      PARTICIPANT_TYPES.IMPORTER,
      PARTICIPANT_TYPES.IS_MP_OPERATOR,
      PARTICIPANT_TYPES.PRODUCER,
    ]).length;

    if (op.type === historyOperationTypes.PAYMENT && !canSeePayment)
      return null;

    return <Operation key={i} op={op} />;
  }

  renderDay(date, day, i) {
    const sorted = orderBy(day, ['operationDate'], ['asc']);
    return (
      <ContentWrapper key={i} hasMrginTop={i > 0}>
        <DayWrap>
          <DateWrap>{moment(date).format('DD MMMM YYYY')}</DateWrap>
          <div>{sorted.map(this.renderOp)}</div>
        </DayWrap>
      </ContentWrapper>
    );
  }

  render() {
    const { data = {} } = this.props;
    const { product: { packageType }, history = [] } = data;
    const historyWithPack = history.map(item => ({ ...item, packageType }));
    const days =
      Array.isArray(historyWithPack) && historyWithPack.length
        ? composeByDay(historyWithPack)
        : {};

    return (
      <div>
        {Object.keys(days)
          .sort((d1, d2) => moment(d1, 'YYYY-MM-DD') - moment(d2, 'YYYY-MM-DD'))
          .map((date, i) => this.renderDay(date, days[date], i))}
      </div>
    );
  }
}

Way.propTypes = {};
export default withRouter(Way);
