/* eslint-disable */

import * as Icons from '@crpt-ui/icons';
import React from 'react';
import styled from 'styled-components';

import { ProductGroupEnum } from './ProductGroupEnum';
import { ReactComponent as Appliances } from './appliances.svg';
import { ReactComponent as Antiseptic } from '../../../svg/antiseptic.svg';

const SvgContainer = styled.div`
  svg {
    width: 20px;
  }
`;

export const ProductGroupIconsMap = Object.freeze({
  [ProductGroupEnum.ALCOHOL]: <Icons.AlcoholSimplified />,
  [ProductGroupEnum.BEER]: <Icons.AlcoholSimplified />,
  [ProductGroupEnum.BICYCLE]: <Icons.BicyclesSimplified />,
  [ProductGroupEnum.ELECTRONICS]: <Icons.ElectronicsSimplified />,
  [ProductGroupEnum.FURS]: <Icons.FursSimplified />,
  [ProductGroupEnum.LP]: <Icons.ClothesSimplified />,
  [ProductGroupEnum.MILK]: <Icons.MilkSimplified />,
  [ProductGroupEnum.OTP]: <Icons.TobaccoAlternativeSimplified />,
  [ProductGroupEnum.PERFUMERY]: <Icons.PerfumerySimplified />,
  [ProductGroupEnum.SHOES]: <Icons.Shoes />,
  [ProductGroupEnum.TIRES]: <Icons.TiresSimplified />,
  [ProductGroupEnum.TOBACCO]: <Icons.Tobacco />,
  [ProductGroupEnum.PHARMA]: <Icons.Medicines />,
  [ProductGroupEnum.WATER]: <Icons.WaterSimplified />,
  [ProductGroupEnum.WHEELCHAIRS]: <Icons.WheelchairsSimplified />,
  [ProductGroupEnum.APPLIANCES]: <SvgContainer><Appliances /></SvgContainer>,
  [ProductGroupEnum.ANTISEPTIC]: <SvgContainer><Antiseptic /></SvgContainer>,
});

export default ProductGroupIconsMap;
