import React, { useMemo, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { Translate } from '../../../../common_components/Translate/Translate';
import { documentStatusesNames, documentTypeNames } from '../../../../constants';
import { Button, themes as BUTTON_THEMES } from '@crpt/react-button';
import { Icon } from '@crpt/react-icon';
import { getDocumentStatusIconByDocStatus } from '../../DocumentDetails.utils';
import { BorderBottomWrapper, CardHeading, PageWrapper, ParagraphHeading } from '../InOutputBasicInfo';
import _ from 'lodash';
import { CorrectionEsfModal } from './correctionEsfModal';
import {Wrapper, StyledLink} from './Esf.styled'

const TYPES  = {
  UZ_ESF: 'Исходный',
  UZ_ESF_COR: 'Исправленный',
  UZ_ESF_ADD: 'Дополнительный',
};

const LineItem = ({ label, value, link, onClick }) => {
  return (
    <BorderBottomWrapper>
      <Grid container spacing={2} alignItems="flex-end">
        <Grid item xs={5}>
          <Box fontWeight={600}>
            {Translate(label)}
          </Box>
        </Grid>
        <Grid item xs={6}>
          {
            link
              ? (<Wrapper><StyledLink to={link} target="_blank">{value}</StyledLink></Wrapper>)
              : onClick
                ? (<Wrapper onClick={onClick}>{value}</Wrapper>)
                : value
          }
        </Grid>
      </Grid>
    </BorderBottomWrapper>
  )
};

LineItem.defaultProps = {
  link: null,
  onClick: null,
};

const EsfBasicInfo = ({ id, selectedDocument }) => {
  const [ open, setOpen ] = useState(false)

  const docStatus = useMemo(() => {
    switch (selectedDocument.status) {
      case 'CHECKED_OK': return 'Обработан успешно';
      case 'CHECKED_NOT_OK': return 'Обработан с ошибками';
      case 'CANCELLED': return 'Аннулирован';
      case 'PROCESSING_ERROR': return 'Ошибка обработки';
      default : return 'Не определен';
    }
  }, [selectedDocument]);

  const sellerAddress = _.get(selectedDocument, 'sellerInfo.address', '');
  const buyerAddress = _.get(selectedDocument, 'buyerInfo.address', '');
  const sellerTaxpayerCode = _.get(selectedDocument, 'sellerInfo.taxpayerCode', '')
  const buyerTaxpayerCode = _.get(selectedDocument, 'buyerInfo.taxpayerCode', '')
  const externalStateId = _.get(selectedDocument, 'externalStateId', null)
  const externalStateName = externalStateId === 15
    ? "Ожидает подписи покупателя"
    : externalStateId === 30
      ? "Принято покупателем"
      : null

  return (
    <PageWrapper>
      <CorrectionEsfModal
        open={open}
        onClose={() => setOpen(false)}
        list={selectedDocument.correctionDocInfo}
      />
      <Grid container justify="space-between">
        <Grid item>
          <CardHeading>
            <Box fontWeight={600}>
              {Translate(documentTypeNames[selectedDocument.type])}
            </Box>
            ID {id}
          </CardHeading>
        </Grid>

        <Grid item pr={0}>
          <Button theme={BUTTON_THEMES.whiteTheme}>
            <Icon
              type={getDocumentStatusIconByDocStatus(selectedDocument.status)}
              spacingRight={9}
            />
            {Translate(docStatus)}
          </Button>
        </Grid>
      </Grid>
      <Box width="75%">
        <ParagraphHeading>{Translate('Общие данные')}</ParagraphHeading>
        <LineItem
          label={'Тип счета-фактуры'}
          value={TYPES[selectedDocument.type]}
        />
        <LineItem
          label={'Номер счета-фактуры'}
          value={selectedDocument.number}
        />
        <LineItem
          label={'Дата счета-фактуры'}
          value={selectedDocument.date}
        />
        <LineItem
          label={'По договору №'}
          value={selectedDocument.contractNumber}
        />
        <LineItem
          label={'от'}
          value={selectedDocument.contractDate}
        />
        {
          (selectedDocument.type === 'UZ_ESF_COR' || selectedDocument.type === 'UZ_ESF_ADD') && (
            <LineItem
              label={'Исправляемый (дополняемый) счет-фактура'}
              value={_.get(selectedDocument, 'primaryDocInfo.number', '-')}
              link={`/documents/list/${_.get(selectedDocument, 'primaryDocInfo.id', '')}`}
            />
          )
        }
        {
          selectedDocument.correctionDocInfo && (selectedDocument.status === 'CANCELLED' || selectedDocument.type === 'UZ_ESF') && selectedDocument.correctionDocInfo.length > 0 && (
            <LineItem
              label={'Корректирующие счета-фактуры'}
              value={Translate('Открыть')}
              onClick={() => setOpen(true)}
            />
          )
        }
        {
          externalStateName && (
            <LineItem
              label={'Внешний статус документа'}
              value={Translate(externalStateName)}
            />
          )
        }
        <br/>
        <ParagraphHeading>{Translate('Поставщик')}</ParagraphHeading>
        <LineItem
          label={'ИНН/ПИНФЛ'}
          value={_.get(selectedDocument, 'sellerInfo.inn', '-')}
        />
        <LineItem
          label={'Наименование'}
          value={_.get(selectedDocument, 'sellerInfo.name', '-')}
        />
        {sellerAddress && (
          <LineItem
            label={'Адрес'}
            value={sellerAddress}
          />
        )}
        {sellerTaxpayerCode && (
          <LineItem
            label={'Код плательщика НДС'}
            value={sellerTaxpayerCode}
          />
        )}
        <br/>
        <ParagraphHeading>{Translate('Покупатель')}</ParagraphHeading>
        <LineItem
          label={'ИНН/ПИНФЛ'}
          value={_.get(selectedDocument, 'buyerInfo.inn', '-')}
        />
        <LineItem
          label={'Наименование'}
          value={_.get(selectedDocument, 'buyerInfo.name', '-')}
        />
        {buyerAddress && (
          <LineItem
            label={'Адрес'}
            value={buyerAddress}
          />
        )}
        {
          buyerTaxpayerCode && (
            <LineItem
              label={'Код плательщика НДС'}
              value={buyerTaxpayerCode}
            />
          )}
      </Box>
    </PageWrapper>
  )
}

export default EsfBasicInfo;
