import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Tooltip as MaterialTooltip } from '@material-ui/core';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Icon } from '@crpt/react-icon';

import { Toast } from '../../../utils/Toast';

import { Text, LinkMail, WrapIcon, TooltipBody } from './Tooltip.styled';
import { connect } from 'react-redux';
import i18next from 'i18next';

const Tooltip = ({
  text,
  showMail,
  typeCopyIcon,
  typeMailIcon,
  colorIcon,
  showCopy,
  sliceTitle,
  textColor,
  setMaxWidth,
  sliceTitleStart,
  sliceTitleEnd,
  mainText,
  textComponent,
  countryCode,
  cursorPointer = true,
  ...props
}) => {

  const onCopy = () => {
    Toast.showSuccess({ content: `${text} успешно скопирован` });
  };

  const handlerBodyClick = e => {
    e.stopPropagation();
  };

  const textBody = mainText || text;

  const email = useMemo(() => {
    return countryCode === 'KZ'
      ? 'mark@ismet.kz'
      : countryCode === 'UZ'
        ? 'support@crpt.ru'
        : 'support@crpt.ru'
  }, [countryCode]);

  return (
    <MaterialTooltip
      title={
        <TooltipBody onClick={handlerBodyClick} setMaxWidth={setMaxWidth}>
          <Text cursorPointer={cursorPointer} textColor={textColor} setMaxWidth={setMaxWidth}>
            {text}
          </Text>
          {(showCopy || showMail) && (
            <WrapIcon>
              {showCopy && (
                <CopyToClipboard text={text} onCopy={onCopy}>
                  <Icon
                    type={typeCopyIcon}
                    spacingRight={5}
                    fill={colorIcon}
                    size={18}
                  />
                </CopyToClipboard>
              )}
              {showMail && (
                <LinkMail
                  href={`mailto:${email}?subject=${i18next.t('Обращение по документу')} ${text}&body=${text}`}
                >
                  <Icon
                    type={typeMailIcon}
                    spacingRight={12}
                    fill={colorIcon}
                    size={18}
                  />
                </LinkMail>
              )}
            </WrapIcon>
          )}
        </TooltipBody>
      }
      {...props}
    >
      {textComponent ? (
        textComponent
      ) : (
        <Text textColor={textColor}>
          {textBody.slice(sliceTitleStart, sliceTitleEnd)}
        </Text>
      )}
    </MaterialTooltip>
  );
};

const mapState = state => {
  return {
    countryCode: state.config.countryCode,
  }
};

const mapDispatch = dispatch => ({});

export default  connect(mapState, mapDispatch)(Tooltip);

Tooltip.propTypes = {
  mainText: PropTypes.string,
  setMaxWidth: PropTypes.number,
  text: PropTypes.string,
  textColor: PropTypes.string,
  sliceTitleStart: PropTypes.number,
  sliceTitleEnd: PropTypes.number,
  showMail: PropTypes.bool,
  showCopy: PropTypes.bool,
  typeCopyIcon: PropTypes.string,
  typeMailIcon: PropTypes.string,
  colorIcon: PropTypes.string,
};

Tooltip.defaultProps = {
  text: '',
  textColor: '#434244',
  typeCopyIcon: 'duplicate',
  typeMailIcon: 'mail',
  colorIcon: '#55B5E7',
};
