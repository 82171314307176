export const historyOperationTypes = {
  EMISSION: 'EMISSION',
  PAYMENT: 'PAYMENT',
  APPLICATION: 'APPLICATION',
  AGGREGATION: 'AGGREGATION',
  OWNER_CHANGE: 'OWNER_CHANGE',
  DISAGGREGATION: 'DISAGGREGATION',
  AUTO_DISAGGREGATION: 'AUTO_DISAGGREGATION',
  DOCUMENT_FIX: 'DOCUMENT_FIX',
  DOCUMENT_CORRECTION: 'DOCUMENT_CORRECTION',
  WITHDRAW: 'WITHDRAW',
  EXPORT: 'EXPORT',
  WRITE_OFF: 'WRITE_OFF',
  RETURN_TO_TURNOVER: 'RETURN_TO_TURNOVER',
  INTRODUCED: 'INTRODUCED',
  CORRECTION_CIS: 'CORRECTION_CIS',
};
